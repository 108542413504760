import React, { useState, useEffect, useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./Carousel.css";
import "./timeline-item.css";

export default function TimelineItem(timeline) {
  const {
    carouselIndex,
    setCarouselIndex,
    // eslint-disable-next-line
    itemChosen,
    setItemChosen,
  } = useContext(TopicContext);

  const [year, setYear] = useState(2021);
  const [event, setEvent] = useState(`lorem`);
  const [thumb, setThumb] = useState(``);
  const [alt, setAlt] = useState(``);
  // const [video, setVideo] = useState(``);
  const [isActive, setActive] = useState(false);

  // const toggleActive = () => {
  //   setActive(!isActive);
  // };

  const updateIndex = (newIndex) => {
    setItemChosen((topic) => false);
    return setCarouselIndex((topic) => newIndex);
  };

  useEffect(() => {
    setYear(timeline.year);
    setEvent(timeline.event);
    setThumb(timeline.imageURL);
    setAlt(timeline.alt);
    // setVideo(timeline.video);
    timeline.index === carouselIndex ? setActive(true) : setActive(false);
  }, [timeline, carouselIndex]);

  // const vid = document.getElementById("video");
  const handleClick = () =>
    isActive
      ? (e) => {
          setItemChosen((topic) =>true)
          e.currentTarget.classList.add("hide");
        }
      : (e) => {
          // vid.classList.add("hide");
          e.currentTarget.classList.remove("hide");
        };

  return (
    <>
      <div
        className={`${
          timeline.index === carouselIndex ? "active" : ""
        } carousel-item`}
      >
        <div className="timeline-media-container">
        
          <div onClick={handleClick()} className="icon-container">
            <img src={thumb} alt={alt} className="timeline-icon" />
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Play%20Button.png"
              className="play-button hide"
              alt="play button"
            />
          </div>
        </div>
        <div
          className="timeline-info-container"
          onClick={() => {
            updateIndex(timeline.index);
            setActive(true);
          }}
        >
          <div className="line-container">
            <hr className={`${timeline.index === 1 ? "hide" : ""} left-line`} />
            <hr
              className={`${timeline.index === 9 ? "hide" : ""} right-line`}
            />
          </div>

          <button className="date-lozenge">
            <h3>{year}</h3>
          </button>
          <div className="event hide">
            <h3>{event}</h3>
          </div>
        </div>
      </div>
    </>
  );
}
